<template>
    <van-popup v-model="show"
               close-on-popstate
               :close-on-click-overlay='false'
               @click-overlay="handleOnClone">
        <div class="modalContent">
            <div class="title">确认提现?</div>
            <div class="withdrawalPrice">
                <div class="company">¥</div>
                <div class="price">{{price}}</div>
            </div>
            <div class="accountWrap"
                 style="margin-bottom: 0.4rem">
                <div>到账账户</div>
                <div class="accountType">
                    <img class="logo"
                         :class="[type != 1?'':'imgOn']"
                         :src="[type != 1 ? require('@/sprite/alipay.png') : require('@/sprite/bank_card.png')]" />
                    <div class="logoName"
                         v-if="type == 1">银行卡</div>
                    <div class="logoName"
                         v-if="type == 2">支付宝</div>
                </div>
            </div>
            <div class="accountWrap">
                <div>综合服务费率</div>
                <div>7.2%</div>
            </div>
            <div class="btnWrap">
                <van-button class="btn"
                            @click="handleOnClone">取消</van-button>
                <van-button class="btn"
                            style="color:#68400B"
                            @click="handleConfirm">确认</van-button>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { Popup, Toast } from 'vant'
import './index.less'
import { getToken } from '@/utils/tokenUtils'
import { UserApplyCash } from '@/service/withdraw'
export default {
    name: 'withdrawalModal',
    props: ['show', 'bankNo', 'type', 'price', 'handleOnCancel', 'handleOk'],
    data() {
        return {
            mid: getToken('mid')
        }
    },
    components: {
        vanPopup: Popup
    },
    created() {},
    mounted() {},
    computed: {
        // 计算属性脱敏
        desensitization() {
            return (value) => {
                return (
                    value.slice(0, 4) +
                    '****' +
                    value.slice(value.length - 4, value.length)
                )
            }
        }
    },
    methods: {
        handleOnClone() {
            this.$emit('handleOnCancel')
        },
        handleConfirm() {
            UserApplyCash({
                amount: Number(this.price),
                accountNo: this.bankNo,
                type: this.type
            }).then((res) => {
                const { code, data, message } = res
                if (code === '0') {
                    this.$emit('handleOk')
                } else {
                    this.$toast(res.message)
                }
            })
        }
    }
}
</script>

<style>
</style>

<template>
    <div class="withdrawals_page">
        <van-loading type="spinner"
                     v-if="loading"
                     color="#F0D19E"
                     vertical>加载中...</van-loading>
        <div class="content"
             v-if="!loading">
            <div class="account"
                 @click="show = true">
                <div>到账账户</div>
                <div>
                    <div class="accountType">
                        <img v-if="bindAccountType == 1 || bindAccountType == 2"
                             class="logo"
                             :class="{'imgOn': bindAccountType == 1}"
                             :src="[bindAccountType == 2 ? require('@/sprite/alipay.png') : bindAccountType == 1 ? require('@/sprite/bank_card.png') : '']" />
                        <!-- <div class="logoName"
                 v-if="bindAccountType == 2">支付宝</div> -->
                        <div class="logoName"
                             v-if="bindAccountType == 1">银行卡</div>
                        <div class="logoName"
                             v-if="bindAccountType == null || !bindAccountType">未绑定</div>
                    </div>
                    <div class="img"></div>
                </div>
            </div>
            <div class="amount">
                <div class="title">提现金额</div>
                <van-form @submit="onSubmit">
                    <van-field v-model="price"
                               label="¥"
                               type="number"
                               name="price"
                               placeholder="0.00"
                               @input="handleOnChange" />
                    <div class="totalPrice">
                        <span>可提现金额¥{{userAccountInfo.balance || 0}}</span>
                        <span style="color: #FF006E; margin-left: 0.27rem;"
                              @click="handleTotal">全部提现</span>
                    </div>
                    <van-button :class="{'btnOn': disabled}"
                                class="btn"
                                round
                                block
                                type="info"
                                native-type="submit"
                                :disabled="disabled">提现</van-button>
                </van-form>
            </div>
            <van-action-sheet v-model="show"
                              @select="onSelect"
                              title="选择到账账户">
                <div class="changeAccount">
                    <div class="accountItem"
                         @click="handleChangeBankCrad(index, item)"
                         v-for="(item, index) in bindAccountList"
                         :key="index">
                        <div class="item">
                            <img v-if="item.accountType == 2"
                                 class="img"
                                 src="@/sprite/alipay.png" />
                            <img v-else
                                 class="img imgOn"
                                 src="@/sprite/bank_card.png"
                                 alt="">
                            <!-- <div v-if="item.accountType == 2">支付宝（{{setZFB(item.accountNo)}}）</div> -->
                            <div v-if="item.accountType == 1">银行卡（{{desensitization(item.accountNo)}}）</div>
                            <span class="update"
                                  @click.stop="handleUpdate(item)">修改</span>
                        </div>
                        <div class="check_box">
                            <img v-if="activeIdx == index"
                                 src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/93ea0f7fe5674618bbc811822f684d6f.png">
                            <img v-if="activeIdx != index"
                                 src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/b2d3d452a0c747d4a3c80fd026348f8f.png">
                        </div>
                        <!-- <van-icon v-if="activeIdx == index"
                                  name="success"
                                  color="#E6BA79"
                                  size="0.43rem"
                                  class="icon" /> -->
                    </div>
                    <!-- <div class="addAlipay accountItem"
                         @click="handleTyingAlipay"
                         v-if="bindAlipayList.length < 3">
                        <div class="item">
                            <van-icon name="add-o"
                                      color="#BDBDBD"
                                      size="0.426667rem"
                                      class="addIcon" />
                            <div>添加支付宝</div>
                        </div>
                    </div> -->
                    <div class="addCard accountItem"
                         @click="handleTyingBankCard"
                         v-if="bindCardList.length < 3">
                        <div class="item">
                            <van-icon name="add-o"
                                      color="#BDBDBD"
                                      size="0.426667rem"
                                      class="addIcon" />
                            <div>添加银行卡</div>
                        </div>
                    </div>
                </div>
            </van-action-sheet>
        </div>
        <with-modal :show="showModal"
                    :price="price"
                    @handleOnCancel="handleOnCancel"
                    @handleOk="handleOk"
                    :type="type"
                    :bankNo="accountNo"></with-modal>
    </div>
</template>

<script>
import './index.less'
import WithdrawalModal from './withdrawalModal'
import { getToken, urlParse } from '@/utils/tokenUtils'
import { Toast } from 'vant'
import { GetUserAccountInfo } from '@/service/my'
import { GetUserBankNewInfos } from '@/service/withdraw'
export default {
    name: 'withdrawal',
    components: {
        'with-modal': WithdrawalModal
    },
    data() {
        return {
            loading: false,
            price: '',
            mid: getToken('mid'),
            disabled: true,
            show: false, // 底部弹起模态面板
            userAccountInfo: {}, // 盟主账户信息
            bindAccountList: [], // 提现账户列表
            activeIdx: 0, // 默认选中
            bindAccountType: null, // 选中的账户类型
            type: 0, // 提现类型 0-微信钱包（暂不可用） 1-银行卡 2-支付宝
            accountNo: 0,
            showModal: false, // 提现弹窗显隐
            bindCardList: [], // 绑定的银行卡数量
            bindAlipayList: [] // 绑定的支付宝账号数量
        }
    },
    computed: {
        // 支付宝脱敏
        setZFB() {
            return (value) => {
                return (
                    value.slice(0, 3) +
                    '****' +
                    value.slice(value.length - 4, value.length)
                )
            }
        },
        // 银行卡脱敏
        desensitization() {
            return (value) => {
                return (
                    value.slice(0, 4) +
                    '****' +
                    value.slice(value.length - 4, value.length)
                )
            }
        }
    },
    created() {
        this.loading = true
        // 在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(
                Object.assign(
                    {},
                    this.$store.state,
                    JSON.parse(sessionStorage.getItem('store'))
                )
            )
        }
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })

        GetUserAccountInfo().then((res) => {
            const { code, data, message } = res
            if (code == '0') {
                this.userAccountInfo = data || {}
            } else {
                this.$toast(message)
            }
        })
        // 获取用户提现账户列表
        GetUserBankNewInfos().then((res) => {
            const { code, data = {} } = res
            if (code == '0') {
                this.bindAccountList = data.list || []
                if (data.list && data.list.length > 0) {
                    this.bindAccountType = data.list[0].accountType
                    this.accountNo = data.list[0].accountNo
                    data.list.forEach((item) => {
                        if (item.accountType == 1) {
                            this.bindCardList.push(item)
                        }
                        if (item.accountType == 2) {
                            this.bindAlipayList.push(item)
                        }
                    })
                }
            } else {
                this.$toast(message)
            }
            this.loading = false
        })
    },
    mounted() {},
    methods: {
        onSelect(item) {
            // 默认情况下点击选项时不会自动收起
            // 可以通过 close-on-click-action 属性开启自动收起
            this.show = false
        },
        handleOnChange(value) {
            this.disabled = value ? false : true
            const reg = value.match(/\d+\.?\d{0,2}/)
            if (reg != null) {
                this.price = reg[0]
            }
        },
        // 点击 全部提现
        handleTotal() {
            this.price = this.userAccountInfo.balance
            this.disabled = false
        },
        // 选择
        handleChangeBankCrad(index, item) {
            this.activeIdx = index
            this.accountNo = item.accountNo
            console.log('item', item)
            this.bindAccountType = item.accountType
            this.show = false
        },
        // 添加支付宝
        handleTyingAlipay() {
            this.$router.push({
                path: '/bindCard',
                query: {
                    bindType: 2
                }
            })
        },
        // 添加银行卡
        handleTyingBankCard() {
            this.$router.push({
                path: '/bindCard',
                query: {
                    bindType: 1
                }
            })
        },
        // 修改银行卡或支付宝账号
        handleUpdate(item) {
            this.$router.push({
                path: '/bindCard',
                query: {
                    id: item.id,
                    bindType: item.accountType
                }
            })
        },
        // 点击 提现
        onSubmit(value) {
            console.log('values', value)
            const urlData = urlParse(window.location.href)
            if (this.userAccountInfo.balance < Number(value.price)) {
                Toast({
                    message: `输入金额超过可提现金额`,
                    className: 'limit'
                })
                return
            }
            if (
                Number(value.price) < this.userAccountInfo.limitWithdrawalAmount
            ) {
                Toast({
                    message: `满${this.userAccountInfo.limitWithdrawalAmount}元可提现`,
                    className: 'limit'
                })
                return
            }
            if (Number(value.price) > 10000) {
                Toast({
                    message: `单次最高可提现10000`,
                    className: 'limit'
                })
                return
            }
            if (!this.bindAccountType) {
                Toast({
                    message: '请先绑定到账账户'
                })
                return
            }
            this.type = this.bindAccountType
            this.showModal = true
        },
        handleOnCancel() {
            this.showModal = false
        },
        handleOk() {
            // console.log("zou ");
            this.showModal = false
            this.$toast({
                message: '提现发起成功',
                className: 'launch',
                icon: 'passed',
                duration: 1000,
                onClose: () => {
                    this.$router.push('/my')
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
</style>